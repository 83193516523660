import MDEditor from '@uiw/react-md-editor';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { getCodeString } from 'rehype-rewrite';
import katex from 'katex';
import 'katex/dist/katex.css';
import React from 'react'

const renderMath = ({ children = [], className, ...props }) => {
  if (typeof children === 'string' && /^\$\$(.*)\$\$/.test(children)) {
    const html = katex.renderToString(`\\boldsymbol{${children.replace(/^\$\$(.*)\$\$/, '$1')}}`, {
      throwOnError: false,
    });
    return <code dangerouslySetInnerHTML={{ __html: html }} style={{ background: 'transparent' }} />;
  }
  const code = props.node && props.node.children ? getCodeString(props.node.children) : children;
  if (
    typeof code === 'string' &&
    typeof className === 'string' &&
    /^language-katex/.test(className.toLocaleLowerCase())
  ) {
    const html = katex.renderToString(code, {
      throwOnError: false,
    });
    return <code style={{ fontSize: '150%' }} dangerouslySetInnerHTML={{ __html: html }} />;
  }
  return <code className={String(className)}>{children}</code>;
};


const MDTextEditor = ({height, editorContent, setEditorContent}) => {
    return <MDEditor height={height} onChange={(value, viewUpdate) => setEditorContent(value)}  value={editorContent}
    previewOptions={{
        components: {
          code: renderMath,
        },
    }}
 />
}

export default MDTextEditor;

export const MDTextViewer = ({height, source, style, className}) => {
    return (<MarkdownPreview
      height={height}
      className={className}
      style={style}
      source={source}
      components={{
        code: renderMath,
      }}
    />);
}

