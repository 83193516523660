import { Box, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import { DoubleArrow as DoubleArrowIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import theme from "../theme";

function Sidebar() {
  const Links = [
    {
      name: "Published Screenings",
      path: "/",
    },

    {
      name: "Published Questions",
      path: "/published-questions",
    },

    {
      name: "Un-Published Screenings",
      path: "/unpublished-screening",
    },

    {
      name: "Add New Question",
      path: "/add-question",
    },

    {
      name: "Add New Screening",
      path: "/add-screening",
    },

    {
      name: "Add Terms and Condition",
      path: "/add-terms-condition",
    },
    {
      name: "Add Privacy Policy",
      path: "/add-privacy-policy",
    },
    {
      name: "Add FAQ",
      path: "/add-faq",
    },
    {
      name: "Add Admin",
      path: "/add-admin",
    },
  ];
  const listItemTextStyle = {
    fontSize: "1rem",
    textDecoration: "none",
    color: "#fff",
  };

  const listItemIconStyle = {
    width: "37px",
    margin: "10px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    display: "center",
  };
  return (
    <>
      {Links.map((item, index) => (
        <ListItem disablePadding key={index}>
          <Link to={item.path} style={listItemTextStyle}>
            <ListItemButton>
              <ListItemIcon>
                <Box sx={listItemIconStyle}>
                  <DoubleArrowIcon
                    sx={{
                      paddingLeft: "5px",
                      paddingTop: "2px",
                      color: "white",
                    }}
                  />
                </Box>
              </ListItemIcon>
              {item.name}
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </>
  );
}
export default Sidebar;
