import React, { useEffect, useState } from "react";
import { Box, Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListSubheader, Typography, Grid, CircularProgress } from "@mui/material";
import { DoubleArrow as DoubleArrowIcon } from "@mui/icons-material";
import theme from "../theme";
import ContestCard from "./ContestCard";
import { Link, useNavigate } from "react-router-dom";
import { getAllContests } from "../utils/action";
import { useDispatch } from "react-redux";
import { Pagination, PaginationItem } from '@mui/material'
import Navbar from "./Navbar";
import Sidebar from "../utils/sidebar";

const drawerWidth = 354;

export default function UnPublished() {

  const dispatch = useDispatch();
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [publishedContests, setPublishedContests] = useState([])
  const [allContest, setAllContest] = useState([])
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();
  useEffect(() => {
      if (
        !localStorage.getItem('access_token') 
      ) {
        navigate('/login');
      }
  
  }, []);

  useEffect(() => {
    // const status = {
    //   status:'10'
    // }
    setIsLoading(true)
    dispatch(getAllContests({ page: currentPage, limit: countPerPage, status:'0'}, (data) => {
      const filteredData = data.data.filter((item) => {
        return (
          (
            item.title.toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
      });
      // Set contests to the entire data.data if category and duration are not specified
      setPublishedContests(filteredData);
      setIsLoading(false)
    }));
  }, [currentPage, countPerPage, searchQuery,]);

  useEffect(() => {

    dispatch(getAllContests({status:'0'}, (data) => {
      // Set contests to the entire data.data if category and duration are not specified
      setAllContest(data.data);
    }));
  }, []);

  const totalPages = Math.ceil(allContest.length / countPerPage);

  const listItemIconStyle = {
    width: "37px",
    margin: "10px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    display: "center",
  };

  const listItemTextStyle = {
    fontSize: "1rem",
    textDecoration: 'none',
    color: '#fff'
  };

  return (
    <Box >
      <Navbar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      <Box sx={{ display: "flex", minHeight: '100vh' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            zIndex:9,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              position: "relative",
              height: "100%",
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider sx={{ backgroundColor: "whitesmoke" }} />
          <Box
            sx={{
              margin: "30px",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "10px",
              color: "white",
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: theme.palette.primary.dark,
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "60px",
                        justifyContent: "center",
                        margin: "10px",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "8px",
                        display: "center",
                      }}
                    >
                      {/* <EmojiEventsIcon
                        sx={{ color: "white", marginTop: "15px" }}
                      /> */}
                    </Box>
                  </ListItemIcon>
                  {/* <ListItemText
                    primary={"Contests"}
                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                  /> */}
                </ListSubheader>
              }
            >
              <Sidebar />
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: theme.palette.primary.dark, p: 3 }}
        >
          {
            (isLoading)
              ?
              <Box sx={{ display: 'flex', justifyContent: 'center', height:'50vh', alignItems:'center' }}>
                <CircularProgress sx={{ color: '#fff' }} />
              </Box>
              :
              publishedContests.length > 0 ?
                <>
                  <Box
                    sx={{
                      margin: "12px",
                      borderRadius: "22px",
                      bgcolor: theme.palette.primary.main,
                      padding: "20px",
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        paddingLeft: "1.3rem",
                        paddingTop: "1rem",
                      }}
                    >
                      Un-Published Contest
                    </Typography>
                    {
                      (isLoading) ?
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress sx={{ color: '#fff' }} />
                        </Box>
                        :
                        <Grid container spacing={2} justifyContent="center" mt="1rem">
                          {
                            publishedContests.map((item, index) => (
                              <Grid item key={index} md={4} xs={12}>
                                <ContestCard Data={item} />
                              </Grid>
                            ))
                          }
                        </Grid>
                    }
                  </Box>

                  <Box my={4} mx={4}>
                    <Pagination
                      count={totalPages} // Use calculated total number of pages
                      shape="rounded"
                      sx={{ float: { md: 'right', xs: 'center' } }}
                      onChange={(event, page) => setCurrentPage(page)}
                      renderItem={(item) => (
                        <PaginationItem
                          component={Link}
                          to="#"
                          disabled={item.page === totalPages} // Disable next icon on last page
                          {...item}
                        />
                      )}
                    />
                  </Box></>

                :
                <Box sx={{ height: '100vh' }}>
                  <Typography
                    sx={{
                      margin: "12px",
                      borderRadius: "22px",
                      bgcolor: theme.palette.primary.main,
                      padding: "20px",
                      color: "white",
                      fontFamily: "inter"

                    }}
                  >
                    No Contests Avalable
                  </Typography>
                </Box>
          }
        </Box>
      </Box>
    </Box>
  );
}


