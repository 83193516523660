import { deleteAPI, getAPI, patchAPI, postAPI, putAPI } from "../utils/api";
import endpoints from "../utils/endpoints";

export const addQuestion = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.ADD_QUESTION,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("access_token")
    );
  };
};

export const addScreening = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.ADD_SCREENING,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("access_token")
    );
  };
};

export const uploadFile = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints.UPLOAD_FILE,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const uploadFiles = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints.UPLOAD_FILES,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getAllQuestions = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoints?.GET_ALL_QUESTIONS,
      payload,
      (response) => {
        callBack(response?.data);
      },
      (error) => {
      },
      localStorage.getItem("access_token")
    );
  };
};

export const getQuestionById = (
  { questionId, payload },
  callBack,
  errorCallback
) => {
  return (dispatch, getState) => {
    getAPI(
      endpoints?.GET_QUESTION_BY_ID + "/" + questionId,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("access_token")
    );
  };
};

export const getScreeningById = (
  { screeningId, payload },
  callBack,
  errorCallback
) => {
  return (dispatch, getState) => {
    getAPI(
      endpoints?.GET_SCREENING_BY_ID + "/" + screeningId,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("access_token")
    );
  };
};

export const updateQuestion = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints.UPDATE_QUESTION_BY_ID + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("access_token")
    );
  };
};

export const updateScreening = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints.UPDATE_SCREENING_BY_ID + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("access_token")
    );
  };
};

export const deleteQuestion = (id, payload, callBack) => {
  return (dispatch) => {
    deleteAPI(
      endpoints.DELETE_QUESTION_BY_ID + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
      },
      localStorage.getItem("access_token")
    );
  };
};

export const deleteScreening = (id, payload, callBack) => {
  return (dispatch) => {
    deleteAPI(
      endpoints.DELETE_SCREENING_BY_ID + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
      },
      localStorage.getItem("access_token")
    );
  };
};
