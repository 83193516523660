import { Stack } from '@mui/material';
import React from 'react';

export default function UploadDataBox({ children }) {
  return (
    <Stack direction={{ md: 'row', xs: 'column', }} spacing={3}>
      {children}
    </Stack>
  );
}
