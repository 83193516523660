import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  List,
  Divider,
  Button,
  ListItemIcon,
  ListSubheader,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import theme from "../theme";
import Navbar from "./Navbar";
import DoneIcon from "@mui/icons-material/Done";
import { enqueueSnackbar } from "notistack";
import { addPrivacyPolicy } from "../utils/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MDTextEditor from "./TextEditor/MDTextEditor";
import Sidebar from "../utils/sidebar";

function AddPrivacyPolicy() {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [Title, setTitle] = useState("");
  const [Content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [editorState, setEditorState] = useState(() =>
  // EditorState.createEmpty());
  const [editorContent, setEditorContent] = useState("");
  const allFieldsFilled = Title && Content;

  // const handleChange = (value) => {
  //     setEditorContent(value);
  //   };

  const dispatch = useDispatch();
  const editor = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
    }
  }, []);

  const listItemIconStyle = {
    width: "37px",
    margin: "10px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    display: "center",
  };

  const drawerWidth = 354;

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "0%",
    top: "0%",
    zIndex: 10,
    width: "100px",
    height: "100px",
    cursor: "pointer",
  };

  const listItemTextStyle = {
    fontSize: "1rem",
    textDecoration: "none",
    color: "#fff",
  };

  const handleSubmit = () => {
    const formData = new FormData();
    // Append broker details to formData
    // const contentState = editorState.getCurrentContent();
    //     const rawText = contentState.getPlainText();
    formData.append("title", Title);
    formData.append("content", editorContent);

    setIsLoading(true);
    dispatch(
      addPrivacyPolicy(
        formData,
        (data) => {
          setIsLoading(false);
          enqueueSnackbar("Privacy Policy Added Successfully!", {
            variant: "success",
          });
          setTitle("");
          setEditorContent("");
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          enqueueSnackbar("Error While Adding Privacy Policy!", {
            variant: "error",
          });
        }
      )
    );
  };

  // const options = {
  //     toolbar: [
  //       "bold",
  //       "italic",
  //       "heading",
  //       "|",
  //       "code",
  //       "quote",
  //       "unordered-list",
  //       "ordered-list",
  //       "|",
  //       "link",
  //       "image",
  //       "|",
  //       "preview",
  //       "side-by-side",
  //       "fullscreen",
  //     ],
  //   };

  return (
    <Box>
      <Navbar />
      <Box sx={{ display: "flex", height: "100%", minHeight: "100vh" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            zIndex: 9,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              position: "relative",
              height: "100%",
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider sx={{ backgroundColor: "whitesmoke" }} />
          <Box
            sx={{
              margin: "30px",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "10px",
              color: "white",
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: theme.palette.primary.dark,
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "60px",
                        justifyContent: "center",
                        margin: "10px",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "8px",
                        display: "center",
                      }}
                    >
                      {/* <EmojiEventsIcon
                                                sx={{ color: "white", marginTop: "15px" }}
                                            /> */}
                    </Box>
                  </ListItemIcon>
                  {/* <ListItemText
                                        primary={"Contests"}
                                        sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                                    /> */}
                </ListSubheader>
              }
            >
              <Sidebar />
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: theme.palette.primary.dark, p: 3 }}
        >
          <Box
            sx={{
              borderRadius: "22px",
              color: "white",
              mx: 2,
              mt: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 3,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              Add Privacy Policy
            </Typography>
            <Accordion
              elevation={0}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
              }}
              defaultExpanded={true}
              onChange={() => setAccordionExpanded(!accordionExpanded)}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {allFieldsFilled ? (
                    <DoneIcon
                      sx={{
                        py: 1,
                        px: 1,
                        bgcolor: "#2DB55D",
                        borderRadius: "26px",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        py: 1,
                        px: 2,
                        background: accordionExpanded ? "#1A1B1E" : "#FB662A",
                        borderRadius: "26px",
                        fontWeight: "bold",
                        fontFamily: "Plus Jakarta Sans",
                      }}
                    >
                      1
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      p: 2,
                      fontWeight: "bold",
                      fontFamily: "Plus Jakarta Sans",
                    }}
                  >
                    Preface
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className="inputFeild"
                      value={Title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter topic title..."
                      InputProps={{
                        sx: {
                          color: "#fff !important",
                          fontFamily: "Plus Jakarta Sans",
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    mt={5}
                    ml={2}
                    className="inputFeild"
                  >
                    <MDTextEditor
                      height={"200px"}
                      editorContent={editorContent}
                      setEditorContent={setEditorContent}
                    />
                  </Grid>
                </Grid>

                <Button
                  sx={{
                    background: "#2DB55D !important",
                    color: "#fff",
                    float: "right",
                    my: 2,
                    px: 3,
                    fontFamily: "Plus Jakarta Sans",
                  }}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "#fff" }} />
                  ) : (
                    "Add"
                  )}
                </Button>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AddPrivacyPolicy;
