// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LOGIN:'/user/admin-login',
    REGISTER: '/user/register',
    ACTIVATION: '/user/activation',
    FORGOT_PASSWORD:'/user/forgotPassword',
    RESET_PASSWORD:'/user/reset',
    GET_USER_INFROMATION:'/user/infor',
    Google_Login: "/user/auth/google",
    ACCESS_TOKEN:'/user/refresh_token',
    UPLOAD_IMAGE:'/upload-image',
    GET_ALL_CONTEST : '/contest/published',
    ADD_TERMS_AND_CONDITION:'/terms-and-conditions',
    ADD_PRIVACY_POLICY:'/privacy-policies',
    ADD_FAQ:'/faqs',
    ADD_ADMIN:'/user/add-user-admin',

    ADD_QUESTION: '/screening/question/add',
    UPLOAD_FILE:'/upload-image',
    UPLOAD_FILES:'/upload-files',
    ADD_SCREENING:'/screening/add',
    GET_ALL_QUESTIONS : '/screening/questions',
    GET_QUESTION_BY_ID: '/screening/admin-question',
    UPDATE_QUESTION_BY_ID: '/screening/admin-question',
    DELETE_QUESTION_BY_ID: '/screening/admin-question',
    GET_SCREENING_BY_ID:  '/screening/admin-screening',
    UPDATE_SCREENING_BY_ID: '/screening/admin-screening',
    DELETE_SCREENING_BY_ID: '/screening/admin-screening',

}