import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  List,
  Divider,
  Button,
  ListItemIcon,
  ListSubheader,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
} from "@mui/material";
import theme from "../theme";
import Navbar from "./Navbar";
import uploadImage from "../components/Image/UploadImage.svg";
import UploadData from "./UploadData";
import UploadDataCard from "./UploadDataCard";
import UploadDataBox from "./UploadDataBox";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  uploadFile,
  getAllQuestions,
  getScreeningById,
  updateScreening,
} from "../utils/screening_actions";
import Sidebar from "../utils/sidebar";
import MultiSelectDropdown from "../utils/MultiSelectDropdown";
import MDTextEditor from "./TextEditor/MDTextEditor";

function EditScreening() {
  function extractDateFromTimestamp(timestamp) {
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(dateObj.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const navigate = useNavigate();
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [topicTitle, setTopicTitle] = useState("");
  const [category, setCategory] = useState("");
  const [prizes, setPrizes] = useState("");
  const [sponsors, setSponsors] = useState("");
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [time, setTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState("");
  const [rules, setRules] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [contestImage, setContestImage] = useState("");
  const allFieldsFilled = topicTitle && category;
  const dispatch = useDispatch();
  const [editorContent, setEditorContent] = useState("");

  const { id } = useParams();

  const [questions, setQuestions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    dispatch(
      getScreeningById({ screeningId: id }, (data) => {
        setTopicTitle(data?.data?.data?.title);
        setCategory(data?.data?.data?.category);
        setPrizes(data?.data?.data?.prize);
        setSponsors(data?.data?.data?.sponsor);
        setDate(data?.data?.data?.endDate.split("T")[0]);
        setTime(data?.data?.data?.endTime);
        setStartDate(data?.data?.data?.startDate.split("T")[0]);
        setStartTime(data?.data?.data?.startTime);
        setDuration(data?.data?.data?.duration);
        setEditorContent(data?.data?.data?.description);
        setRules(data?.data?.data?.rules);
        setContestImage(data?.data?.data?.imageUrl);
        setSelectedIds(data?.data?.data?.questionIds);

        const selected_opts = questions.filter((e) =>
          data?.data?.data?.questionIds.some((val) => e.key === val)
        );
        setSelectedOptions(selected_opts);
      })
    );
  }, [dispatch, questions]);

  useEffect(() => {
    dispatch(
      getAllQuestions({}, (data) => {
        let results = data.data;
        results = results.map((el) => ({
          key: el._id,
          value: el.questionHeading,
        }));
        setQuestions(results);
      })
    );
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadFile(
        formData,
        (data) => {
          // onSaveChangesClick(data.value)
          setContestImage(data.data.imagesKey[0]);
        },
        (err) => {
          setIsLoading(false);
        }
      )
    );
  };

  const drawerWidth = 354;

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "0%",
    top: "0%",
    zIndex: 10,
    width: "100px",
    height: "100px",
    cursor: "pointer",
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("title", topicTitle);
    formData.append("rules", rules);
    formData.append("category", category);
    formData.append("questionIds", selectedIds);
    formData.append("prize", prizes);
    formData.append("sponsor", sponsors);
    formData.append("description", editorContent);
    formData.append("startDate", startDate);
    formData.append("startTime", startTime);
    formData.append("endDate", date);
    formData.append("endTime", time);
    formData.append("duration", duration);
    formData.append("imageUrl", contestImage);
    setIsLoading(true);
    dispatch(
      updateScreening(
        id,
        formData,
        (data) => {
          setIsLoading(false);
          enqueueSnackbar("Contests Added Successfully!", {
            variant: "success",
          });
          navigate("/");
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          if (err?.response?.data?.msg) {
            enqueueSnackbar(err?.response?.data?.msg, { variant: "error" });
          } else {
            enqueueSnackbar("Error While Updating Contest!", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const selected_opts = questions.filter((e) =>
      value.some((val) => e.key === val)
    );

    setSelectedOptions(selected_opts);
    setSelectedIds(value);
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ display: "flex", height: "100%" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            zIndex: 9,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              position: "relative",
              height: "100%",
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider sx={{ backgroundColor: "whitesmoke" }} />
          <Box
            sx={{
              margin: "30px",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "10px",
              color: "white",
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: theme.palette.primary.dark,
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "60px",
                        justifyContent: "center",
                        margin: "10px",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "8px",
                        display: "center",
                      }}
                    ></Box>
                  </ListItemIcon>
                </ListSubheader>
              }
            >
              <Sidebar />
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: theme.palette.primary.dark, p: 3 }}
        >
          <Box
            sx={{
              borderRadius: "22px",
              color: "white",
              mx: 2,
              mt: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 3,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              Edit Screening
            </Typography>
            <Accordion
              elevation={0}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
              }}
              defaultExpanded={true}
              onChange={() => setAccordionExpanded(!accordionExpanded)}
            >
              <AccordionSummary aria-controls="panel-content" id="panel-header">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {allFieldsFilled ? (
                    <DoneIcon
                      sx={{
                        py: 1,
                        px: 1,
                        bgcolor: "#2DB55D",
                        borderRadius: "26px",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        py: 1,
                        px: 2,
                        background: accordionExpanded ? "#1A1B1E" : "#FB662A",
                        borderRadius: "26px",
                        fontWeight: "bold",
                        fontFamily: "Plus Jakarta Sans",
                      }}
                    >
                      1
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      p: 2,
                      fontWeight: "bold",
                      fontFamily: "Plus Jakarta Sans",
                    }}
                  >
                    Preface
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDetails>
                  <h2>Select questions:</h2>
                  <MultiSelectDropdown
                    options={questions}
                    selectedOptions={selectedIds}
                    onChange={handleChange}
                  />
                  <div>
                    Selected questions:
                    {selectedOptions.map((option) => (
                      <span key={option.key}>{option.value}, </span>
                    ))}
                  </div>
                </AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className="inputFeild"
                      value={topicTitle}
                      onChange={(e) => setTopicTitle(e.target.value)}
                      placeholder="Enter topic title..."
                      InputProps={{
                        sx: {
                          color: "#fff !important",
                          fontFamily: "Plus Jakarta Sans",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      sx={{ width: "100%", fontFamily: "Plus Jakarta Sans" }}
                    >
                      <InputLabel
                        sx={{
                          fontFamily: "Plus Jakarta Sans",
                          color: "#686868",
                        }}
                      >
                        Choose Category
                      </InputLabel>
                      <Select
                        className="inputFeild"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        IconComponent={() => <div>▼</div>}
                      >
                        <MenuItem
                          value="Regular"
                          sx={{ fontFamily: "Plus Jakarta Sans" }}
                        >
                          Regular
                        </MenuItem>
                        <MenuItem
                          value="Sponsored"
                          sx={{ fontFamily: "Plus Jakarta Sans" }}
                        >
                          Sponsored
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className="inputFeild"
                      value={prizes}
                      onChange={(e) => setPrizes(e.target.value)}
                      placeholder="Enter Prizes"
                      InputProps={{
                        sx: {
                          color: "#fff !important",
                          fontFamily: "Plus Jakarta Sans",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className="inputFeild"
                      value={sponsors}
                      onChange={(e) => setSponsors(e.target.value)}
                      placeholder="Enter Sponsors"
                      InputProps={{
                        sx: {
                          color: "#fff !important",
                          fontFamily: "Plus Jakarta Sans",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      type="date"
                      sx={{ width: "100%" }}
                      value={extractDateFromTimestamp(startDate)}
                      onChange={(e) => setStartDate(e.target.value)}
                      placeholder="Enter Contest Start Date"
                      InputProps={{
                        sx: {
                          color: "#FFFFFF66",
                          fontFamily: "Plus Jakarta Sans",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      type="time"
                      sx={{ width: "100%" }}
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      placeholder="Start Time"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <TextField
                      type="date"
                      sx={{ width: "100%" }}
                      value={extractDateFromTimestamp(date)}
                      onChange={(e) => setDate(e.target.value)}
                      placeholder="Enter Contest End Date"
                      InputProps={{
                        sx: {
                          color: "#FFFFFF66",
                          fontFamily: "Plus Jakarta Sans",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      type="time"
                      sx={{ width: "100%" }}
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      placeholder=" Time"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      className="inputFeild custom-placeholder-color"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      placeholder="Duration"
                      InputProps={{
                        sx: {
                          color: "#fff",
                          fontFamily: "Plus Jakarta Sans",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              m: 0,
                              p: 0,
                              alignItems: "center",
                            }}
                          >
                            <KeyboardArrowUpIcon
                              sx={{ color: "#686868", m: 0, p: 0 }}
                            />
                            <KeyboardArrowDownIcon
                              sx={{ color: "#686868", m: 0, p: 0 }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      className="inputFeild"
                      value={rules}
                      onChange={(e) => setRules(e.target.value)}
                      placeholder="Enter rules"
                      InputProps={{
                        sx: {
                          color: "#fff !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    mt={5}
                    ml={2}
                    className="inputFeild"
                  >
                    <MDTextEditor
                      height="200px"
                      setEditorContent={setEditorContent}
                      editorContent={editorContent}
                    />
                  </Grid>
                </Grid>
                <Grid item md={4} sx={{ py: 2 }}>
                  <div>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Plus Jakarta Sans",
                      }}
                    >
                      {" "}
                      Contest Image
                    </Typography>
                    <TextField
                      type="file"
                      style={{ display: "none" }}
                      id="upload-image"
                    />

                    <UploadDataBox>
                      <UploadData>
                        <UploadDataCard>
                          {contestImage ? (
                            <img
                              src={contestImage ? contestImage : ""}
                              style={{
                                width: "101px",
                                height: "101px",
                                objectFit: "cover",
                              }}
                              alt="upload"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={uploadImage}
                              alt="upload"
                              className="img-fluid"
                            />
                          )}
                          <input
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            multiple
                            style={hideInput}
                            onChange={handleImageChange}
                          />
                        </UploadDataCard>
                      </UploadData>
                    </UploadDataBox>
                  </div>
                </Grid>

                <Button
                  sx={{
                    background: "#2DB55D !important",
                    color: "#fff",
                    float: "right",
                    my: 2,
                    px: 3,
                  }}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "#fff" }} />
                  ) : (
                    "Done"
                  )}
                </Button>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EditScreening;
