import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  List,
  Divider,
  Button,
  ListItemIcon,
  ListSubheader,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import theme from "../theme";
import Navbar from "./Navbar";
import uploadImage from "../components/Image/UploadImage.svg";
import UploadData from "./UploadData";
import UploadDataCard from "./UploadDataCard";
import UploadDataBox from "./UploadDataBox";
import DoneIcon from "@mui/icons-material/Done";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import csv from "csvtojson";
import Sidebar from "../utils/sidebar";
import {
  getQuestionById,
  updateQuestion,
  uploadFile,
  uploadFiles,
} from "../utils/screening_actions";
import MDTextEditor from "./TextEditor/MDTextEditor";

function EditQuestion() {
  const navigate = useNavigate();
  const [problemHeading, setProblemHeading] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkerCodesImage, setCheckerCodesImage] = useState("");
  const [testCasesFiles, setTestCasesFiles] = useState([]);
  const [jsonArray, setJsonArray] = useState([]);
  const allFieldsFilled2 = problemHeading;
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(
      getQuestionById({ questionId: id }, (data) => {
        setProblemHeading(data?.data?.data?.questionHeading);
        setQuestionDescription(data?.data?.data?.questionDescription);
        setJsonArray(data?.data?.data?.testcase);
        setCheckerCodesImage(data?.data?.data?.codecheck);
      })
    );
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
    }
  }, []);

  const handleCheckerUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      var formData = new FormData();
      formData.append("file", uploadedFile);
      dispatch(
        uploadFile(
          formData,
          (data) => {
            setCheckerCodesImage(data.data.imagesKey[0]);
          },
          (err) => {
            enqueueSnackbar("Error Occured! Please try again", {
              variant: "error",
            });
          }
        )
      );
    }
  };

  const handleTestCaseUpload = (event) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      var formData = new FormData();
      for (var x = 0; x < uploadedFiles.length; x++) {
        formData.append("fileToUpload[]", uploadedFiles[x]);
      }
      dispatch(
        uploadFiles(
          formData,
          (data) => {
            setTestCasesFiles(data.data.filesKey);
          },
          (err) => {
            enqueueSnackbar("Error Occured! Please try again", {
              variant: "error",
            });
          }
        )
      );
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const convertCsvToJson = (csvString) => {
    return csv().fromString(csvString);
  };

  const handleTestCase = async (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file?.name?.toString()?.toLowerCase()?.includes(".csv")) {
        const csvString = await readFile(file);
        const convertedJsonArray = await convertCsvToJson(csvString);
        setJsonArray(convertedJsonArray);
      } else {
        enqueueSnackbar("please upload a csv file", { variant: "warning" });
      }
    }
  };

  const drawerWidth = 354;

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "0%",
    top: "0%",
    zIndex: 10,
    width: "100px",
    height: "100px",
    cursor: "pointer",
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("questionHeading", problemHeading);
    formData.append("questionDescription", questionDescription);
    formData.append("testcase", JSON.stringify(jsonArray));
    formData.append("codecheck", checkerCodesImage);
    for (var x = 0; x < testCasesFiles.length; x++) {
      formData.append("testcasefiles", testCasesFiles[x]);
    }
    setIsLoading(true);
    dispatch(
      updateQuestion(
        id,
        formData,
        (data) => {
          setIsLoading(false);
          enqueueSnackbar("Question Edited Successfully!", {
            variant: "success",
          });
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          if (err?.response?.data?.msg) {
            enqueueSnackbar(err?.response?.data?.msg, { variant: "error" });
          } else {
            enqueueSnackbar("Error While Updating Contest!", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ display: "flex", height: "100%" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            zIndex: 9,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              position: "relative",
              height: "100%",
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider sx={{ backgroundColor: "whitesmoke" }} />
          <Box
            sx={{
              margin: "30px",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "10px",
              color: "white",
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: theme.palette.primary.dark,
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "60px",
                        justifyContent: "center",
                        margin: "10px",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "8px",
                        display: "center",
                      }}
                    ></Box>
                  </ListItemIcon>
                </ListSubheader>
              }
            >
              <Sidebar />
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: theme.palette.primary.dark, p: 3 }}
        >
          <Box
            sx={{
              borderRadius: "22px",
              color: "white",
              mx: 2,
              mt: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 3,
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              Edit Question
            </Typography>
            <Accordion
              elevation={0}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
              }}
              defaultExpanded={false}
              expanded={true}
            >
              <AccordionSummary aria-controls="panel-content" id="panel-header">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {allFieldsFilled2 ? (
                    <DoneIcon
                      sx={{
                        py: 1,
                        px: 1,
                        bgcolor: "#2DB55D",
                        borderRadius: "26px",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        py: 1,
                        px: 2,
                        background: "#FB662A",
                        borderRadius: "26px",
                        fontWeight: "bold",
                        fontFamily: "Plus Jakarta Sans",
                      }}
                    >
                      1
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      p: 2,
                      fontWeight: "bold",
                      fontFamily: "Plus Jakarta Sans",
                    }}
                  >
                    Problem Statement
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      className="inputFeild"
                      value={problemHeading}
                      onChange={(e) => setProblemHeading(e.target.value)}
                      placeholder="Enter problem heading"
                      InputProps={{
                        sx: {
                          color: "#fff !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    mt={5}
                    ml={2}
                    className="inputFeild"
                  >
                    <MDTextEditor
                      height="200px"
                      setEditorContent={setQuestionDescription}
                      editorContent={questionDescription}
                    />
                  </Grid>

                  <Grid item md={4} sx={{ py: 2 }}>
                    <div>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Plus Jakarta Sans",
                        }}
                      >
                        {" "}
                        Test Cases
                      </Typography>

                      <UploadDataBox>
                        <UploadData>
                          <UploadDataCard>
                            {jsonArray?.length > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <InsertDriveFileIcon
                                  sx={{
                                    color: "#2DB55D",
                                    fontSize: "4rem !important",
                                  }}
                                />
                              </Box>
                            ) : (
                              <img
                                src={uploadImage}
                                alt="upload"
                                className="img-fluid"
                              />
                            )}
                            <input
                              type="file"
                              accept=".csv"
                              multiple
                              style={hideInput}
                              onChange={handleTestCase}
                            />
                          </UploadDataCard>
                        </UploadData>
                      </UploadDataBox>
                    </div>
                  </Grid>

                  <Grid item md={4} sx={{ py: 2 }}>
                    <div>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Plus Jakarta Sans",
                        }}
                      >
                        {" "}
                        Checker Code
                      </Typography>
                      <TextField
                        type="file"
                        style={{ display: "none" }}
                        id="upload-image"
                      />

                      <UploadDataBox>
                        <UploadData>
                          <UploadDataCard>
                            {checkerCodesImage ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <InsertDriveFileIcon
                                  sx={{
                                    color: "#2DB55D",
                                    fontSize: "4rem !important",
                                  }}
                                />
                                {checkerCodesImage?.name}
                              </Box>
                            ) : (
                              <img
                                src={uploadImage}
                                alt="upload"
                                className="img-fluid"
                              />
                            )}
                            <input
                              type="file"
                              accept=".cpp"
                              multiple
                              style={hideInput}
                              onChange={handleCheckerUpload}
                            />
                          </UploadDataCard>
                        </UploadData>
                      </UploadDataBox>
                    </div>
                  </Grid>

                  <Grid item md={4} sx={{ py: 2 }}>
                    <div>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Plus Jakarta Sans",
                        }}
                      >
                        {" "}
                        Test Cases files
                      </Typography>
                      <TextField
                        type="file"
                        style={{ display: "none" }}
                        id="upload-image"
                      />

                      <UploadDataBox>
                        <UploadData>
                          <UploadDataCard>
                            {testCasesFiles.length > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <InsertDriveFileIcon
                                  sx={{
                                    color: "#2DB55D",
                                    fontSize: "4rem !important",
                                  }}
                                />
                                {testCasesFiles[0]?.name}
                              </Box>
                            ) : (
                              <img
                                src={uploadImage}
                                alt="upload"
                                className="img-fluid"
                              />
                            )}
                            <input
                              type="file"
                              accept=".txt,.zip"
                              multiple="multiple"
                              style={hideInput}
                              onChange={handleTestCaseUpload}
                            />
                          </UploadDataCard>
                        </UploadData>
                      </UploadDataBox>
                    </div>
                  </Grid>
                </Grid>
                <Button
                  sx={{
                    background: "#2DB55D !important",
                    color: "#fff",
                    float: "right",
                    my: 2,
                    px: 3,
                  }}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "#fff" }} />
                  ) : (
                    "Done"
                  )}
                </Button>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EditQuestion;
