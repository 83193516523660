import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

export default function UploadData({ children, name, mt }) {
  return (
    <Box>
      <Typography variant="text" sx={{ color: 'mute.text' }}>
        {name}
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={2}
        sx={{ mt: mt ? mt : 1 }}
      >
        {children}
      </Stack>
    </Box>
  );
}
