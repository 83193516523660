import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../theme";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

const ContestCard = ({ Data , setOpen, setId}) => {
  const navigate = useNavigate();

  function DateFormat(timestamp) {
    const options = {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
  };

    const formattedDate = new Date(timestamp).toLocaleString('en-US', options);
    return formattedDate;
  }

  const handleDelete = (id) => {
    setOpen(true);
    setId(id);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          width: "24rem",
        },
      }}
    >
      <Paper elevation={0} sx={{ bgcolor: theme.palette.primary.dark }}>
        <Box
          sx={{
            display: "block",
            margin: "1rem",
            backgroundImage: `url(${Data?.imageUrl || "https://images.unsplash.com/photo-1508611087707-c9d807892372?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80my-bird.jpg"})`,
            backgroundSize: "cover",
            borderRadius: "12px",
            backgroundPosition: "center",
            height: "9rem",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginInline: "1rem",
              paddingTop: ".5rem",
            }}
          >
            <IconButton
              onClick={() => navigate(`/edit-screening/${Data?._id}`)}

            >
              <EditIcon
                sx={{
                  fontSize: 14,
                  bgcolor: theme.palette.primary.light,
                  p: ".4rem",
                  borderRadius: "100%",
                }}
              />
            </IconButton>
            <IconButton
            onClick={() => handleDelete(Data?._id)}>
              <DeleteIcon
                sx={{
                  fontSize: 14,
                  color: "red",
                  bgcolor: theme.palette.primary.light,
                  p: ".4rem",
                  borderRadius: "100%",
                }}
              />
            </IconButton>
          </Box>

          <Typography
            sx={{
              display: "flex",
              flexDirection: "revert",
              justifyContent: "flex-end",
              fontSize: "10px",
              fontWeight: "600",
              backgroundColor: "white",
              borderRadius: "50px",
              marginRight: "0.7rem",
              paddingX: "1rem",
              paddingY: ".3rem",
              marginTop: 7,
              float: "right",
              width: "fit-content",
            }}
          >
            Duration: {Data?.duration} mins
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'block',
            color: "white",
            paddingInline: "1.3rem",
            textAlign: "justify",
          }}
        >
          <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
            {Data?.title}
          </Typography>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            my: 2,
          }}>
            <Typography sx={{
              fontSize: 12,

            }}>
              {DateFormat(Data?.startDate)}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                borderRadius: '20px',
                px: 1.5,
                py: 0.5,
                background: '#fff',
                color: '#000',
                fontWeight: '600'
              }}
            >
              {Data?.category}
            </Typography>
          </Box>
        </Box>
      </Paper >
    </Box >
  );
};

export default ContestCard;
