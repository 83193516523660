import { Card, CardActionArea } from '@mui/material';
import React from 'react';

export const uplodeImageBtn = {
    borderRadius: "20px",
    p: 1,
    background: "#1A1B1E",
    border:'1px dashed #8D98AA',
    height: 100,
    width: 100,
    display: "flex",
    alignItems: "center",
    boxShadow: "0",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      opacity: "0",
    },
    "& .button": {
      display: "block",
      position: "relative",
    },
    "& .icon": {
      position: "absolute",
      bottom: 0,
      right: 0,
      background: "#1A1B1E",
      borderRadius: 10,
      color: "#fff",
      width: 25,
      height: 25,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& img": {
      borderRadius: "20px",
      width: "100%",
      height: "auto",
      mb: "-5px",
    },
    "& svg": {
      fontSize: "1rem",
    },
  };

export default function UploadDataCard({ children }) {
  return (
    <Card sx={uplodeImageBtn} >
      <CardActionArea className="button">{children}</CardActionArea>
    </Card>
  );
}
