import { deleteAPI, getAPI, patchAPI, postAPI, putAPI } from "../utils/api";
import endpoints from "../utils/endpoints";



// Post Apis


export const login = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.LOGIN,
            payload,
            (response) => {
                callBack(response);
            },
            (error) => {
                errorCallback(error)
            },
        );
    };
};

export const addTermsAndCondition = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints?.ADD_TERMS_AND_CONDITION,
            payload,
            (response) => {
                callBack(response);
            },
          (error) => {
                errorCallback(error);
            },localStorage.getItem('access_token')
        );
    };
}

export const addPrivacyPolicy = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints?.ADD_PRIVACY_POLICY,
            payload,
            (response) => {
                callBack(response);
            },
          (error) => {
                errorCallback(error);
            },localStorage.getItem('access_token')
        );
    };
}

export const addFaq = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints?.ADD_FAQ,
            payload,
            (response) => {
                callBack(response);
            },
          (error) => {
                errorCallback(error);
            },localStorage.getItem('access_token')
        );
    };
}

export const addAdmin = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints?.ADD_ADMIN,
            payload,
            (response) => {
                callBack(response);
            },
            (error) => {
                errorCallback(error);
            },localStorage.getItem('access_token')
        );
    };
}

export const uploadImages = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.UPLOAD_IMAGE,
            payload,
            (response) => {
                callBack(response);
            },
            (error) => {
                errorCallback(error);
            },
        );
    }
}


// Get Apis

export const getAllContests = (payload, callBack) => {
    return (dispatch, getState) => {
        getAPI(
            endpoints?.GET_ALL_CONTEST,
            payload,
            (response) => {
                callBack(response?.data);
            },
            (error) => {
            }
        );
    };
};

