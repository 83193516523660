import React, { useEffect, useState } from "react";
import { Box, Drawer, List, Divider, ListItemIcon, ListSubheader, Typography, Grid, CircularProgress, Dialog, DialogContent, Button } from "@mui/material";
import theme from "../theme";
import ContestCard from "./ContestCard";
import { Link, useNavigate } from "react-router-dom";
import { getAllContests } from "../utils/action";
import { useDispatch } from "react-redux";
import { Pagination, PaginationItem } from '@mui/material'
import Navbar from "./Navbar";
import { enqueueSnackbar } from "notistack";
import Sidebar from "../utils/sidebar";
import { deleteScreening } from "../utils/screening_actions";

const drawerWidth = 354;

export default function SideDrawer() {

  const dispatch = useDispatch();

  const [publishedContests, setPublishedContests] = useState([])
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allContest, setAllContest] = useState([])
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");


  useEffect(() => {
    setIsLoading(true)
    dispatch(getAllContests({ pageNo: currentPage, pageSize: countPerPage, status: '1' }, (data) => {
      setIsLoading(false)
      setPublishedContests(data.data);
    }));
  }, []);

  useEffect(() => {

    dispatch(getAllContests({ status: '1' }, (data) => {

      // Set contests to the entire data.data if category and duration are not specified
      setAllContest(data.data);
    }));
  }, []);

  const totalPages = Math.ceil(allContest.length / countPerPage);

  const navigate = useNavigate();
  useEffect(() => {
      if (
        !localStorage.getItem('access_token') 
      ) {
        navigate('/login');
      }
  
  }, []);


  const onDelete = () => {
    setOpen(false);
    dispatch(
      deleteScreening(id, {}, (data) => {
        enqueueSnackbar("Contests Deleted Successfully!", { variant: 'success' })
        setIsLoading(false);
        window.location.reload()
      }, (err) => {
        enqueueSnackbar("Error While Deleting Contests!", { variant: 'error' })
      }
      )
    )
}
  return (
    <>
      <Navbar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      <Box sx={{ display: "flex", minHeight: '100vh' }}>

        <Drawer
          sx={{
            width: drawerWidth,
            zIndex:9,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              position: "relative",
              height: "100%",
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider sx={{ backgroundColor: "whitesmoke" }} />
          <Box
            sx={{
              margin: "30px",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "10px",
              color: "white",
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: theme.palette.primary.dark,
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "60px",
                        justifyContent: "center",
                        margin: "10px",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "8px",
                        display: "center",
                      }}
                    >
                    </Box>
                  </ListItemIcon>
                </ListSubheader>
              }
            >
            <Sidebar />
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: theme.palette.primary.dark, p: 3 }}
        >
          {
            (isLoading) ?
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems:'center', height:'50vh' }}>
                <CircularProgress sx={{ color: '#fff' }} />
              </Box>
              :

              publishedContests.length > 0 ?
                <>
                  <Box
                    sx={{
                      margin: "12px",
                      borderRadius: "22px",
                      bgcolor: theme.palette.primary.main,
                      padding: "20px",
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        paddingLeft: "1.3rem",
                        paddingTop: "1rem",
                      }}
                    >
                      Published Contest
                    </Typography>

                    {/* <Box className="search style-1" >
                  <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                </Box> */}
                    {
                      (isLoading) ?
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress sx={{ color: '#fff' }} />
                        </Box>
                        :
                        <Grid container spacing={2} justifyContent="center" mt="1rem">
                          {
                            publishedContests.map((item, index) => (
                              <Grid item key={index} md={4} xs={12}>
                                <ContestCard setOpen={setOpen} setId={setId} Data={item} />
                              </Grid>
                            ))
                          }
                        </Grid>
                    }
                  </Box>

                  <Box my={4} mx={4}>
                    <Pagination
                      count={totalPages} // Use calculated total number of pages
                      shape="rounded"
                      sx={{ float: { md: 'right', xs: 'center' } }}
                      onChange={(event, page) => setCurrentPage(page)}
                      renderItem={(item) => (
                        <PaginationItem
                          component={Link}
                          to="#"
                          disabled={item.page === totalPages} // Disable next icon on last page
                          {...item}
                        />
                      )}
                    />
                  </Box></>

                :
                <Box sx={{ height: '100vh' }}>
                  <Typography
                    sx={{
                      margin: "12px",
                      borderRadius: "22px",
                      bgcolor: theme.palette.primary.main,
                      padding: "20px",
                      color: "white",
                      fontFamily: "inter"

                    }}
                  >
                    No Contests Avalable
                  </Typography>
                </Box>

          }
        </Box>
      </Box>
      <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={"lg"}
            >
                {/* <DialogTitle>
                    <Typography align="center" sx={{ fontSize: '80px', color: 'red' }}>
                        <AiOutlineCloseCircle />
                    </Typography>
                </DialogTitle> */}
                <DialogContent>
                    <Typography variant="h6" sx={{fontWeight:700}}>
                        Are you sure you want to delete this contest ?
                    </Typography>
                    <Grid container mt={5} spacing={2} justifyContent={'center'}>
                        <Grid md={3} xs={0} item></Grid>
                        <Grid md={3} item>
                            <Button variant="contained" onClick={onDelete}>Yes</Button>
                        </Grid>
                        <Grid md={3} item>
                            <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                        </Grid>
                        <Grid md={3} xs={0} item></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
      
      </>
  );
}


