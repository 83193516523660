import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import "./App.css";
import SideDrawer from "./components/SideDrawer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UnPublished from "./components/UnPublished";
import AddTermsAndCondition from "./components/AddTermsAndCondition";
import AddPrivacyPolicy from "./components/AddPrivacyPolicy";
import AddFaq from "./components/AddFaq.js";
import Login from "./components/Login.js";
import AddAdmin from "./components/AddAdmin.js";
import AddQuestion from "./components/AddQuestion.js";
import AddScreening from "./components/AddScreening.js";
import EditQuestion from "./components/EditQuestion.js";
import Questions from "./components/Questions.js";
import EditScreening from "./components/EditScreening.js";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Home */}
        <Route path="/" element={<>
          <ThemeProvider theme={theme}>
            {/* <Navbar /> */}
            <SideDrawer />
          </ThemeProvider>
        </>} />

        <Route path="/edit-screening/:id" element={
          <ThemeProvider theme={theme}>
            <EditScreening />
          </ThemeProvider>
        } />

        <Route path="/add-question" element={
          <ThemeProvider theme={theme}>
            <AddQuestion />
          </ThemeProvider>
        } />
        
        <Route path="/add-screening" element={
          <ThemeProvider theme={theme}>
            <AddScreening />
          </ThemeProvider>
        } />

        <Route path="/edit-question/:id" element={
          <ThemeProvider theme={theme}>
            <EditQuestion />
          </ThemeProvider>
        } />

        <Route path="/published-questions" element={<>
          <ThemeProvider theme={theme}>
            <Questions />
          </ThemeProvider>
        </>} />

        <Route path="/unpublished-screening" element={<>
          <ThemeProvider theme={theme}>
            <UnPublished />
          </ThemeProvider>
        </>} />

        <Route path="/add-terms-condition" element={<>
          <ThemeProvider theme={theme}>
            <AddTermsAndCondition />
          </ThemeProvider>
        </>} />

        <Route path="/add-privacy-policy" element={<>
          <ThemeProvider theme={theme}>
            <AddPrivacyPolicy />
          </ThemeProvider>
        </>} />
        <Route path="/add-faq" element={<>
          <ThemeProvider theme={theme}>
            <AddFaq />
          </ThemeProvider>
        </>} />
        <Route path="/login" element={<>
          <ThemeProvider theme={theme}>
            <Login />
          </ThemeProvider>
        </>} />

        <Route path="/add-admin" element={<>
          <ThemeProvider theme={theme}>
            <AddAdmin />
          </ThemeProvider>
        </>} />

      </Routes>

  

    </BrowserRouter>
  );
}

export default App;
