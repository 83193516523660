import { Box, Button, Card, CardContent, CircularProgress, Container, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { login } from "../utils/action";
import Navbar from "./Navbar";
import theme from "../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const togglePasswordVisibility = () => setShowPassword((show) => !show);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    if (email && password) {
      setLoading(true)
      let payload = {
          email: email,
          password: password,
      }

      dispatch(
        login(
          payload,
          (data) => {
            localStorage.setItem("access_token", data.data.access_token)
            dispatch
            ({
                type: "LOG_IN",
                payload: data?.data?.user
            })
            setLoading(false);
            setEmail("");
            setPassword("");
            enqueueSnackbar("Logged In Successfully", { variant: "success", });
            navigate('/')
          },
          (err) => {
            enqueueSnackbar(err?.response?.data?.msg, { variant: "error", });
            setLoading(false);
          }
        )
      );

    } else {
      enqueueSnackbar("Email & Password cannot be empty", { variant: "warning" });
    }
  };



  return (
    <>
      <Box sx={{ position: 'relative', overflow: 'hidden', bgcolor: theme.palette.primary.dark, }}>
  
     <Navbar/>
        <Container
          sx={{
            minHeight: {md:"calc(100vh - 65px)", xs:"calc(100vh - 56px)"},
            display:'flex', justifyContent:'center', alignItems:'center'
          }}
          


        >
          <Card
            variant={"outlined"}
            component={"form"}

            sx={{ maxWidth: 400, width: "100%", backgroundColor: theme?.palette?.primary?.main, }}
          >
            <CardContent>
              <Box sx={{ mt: 2, textAlign: 'center' }} flexgrow={1}>
                <Typography sx={{color: "white", fontSize:'24px', fontWeight:600}}>Login to Continue</Typography>
                <Grid container spacing={2} mt={5} p={3} >
                  <Grid item xs={12} md={12}>
                  
                    <TextField
                      className="inputFeild"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Username"
                      InputProps={{
                        sx: {
                          color: "#fff !important",
                          fontFamily: "Plus Jakarta Sans",
                        },
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={12}>

                      <TextField
      className="inputFeild"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      placeholder="Enter Password"
      InputProps={{
        sx: {
          color: "#fff !important",
          fontFamily: "Plus Jakarta Sans",
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={togglePasswordVisibility}
              sx={{ color: "#fff" }}
            >
              {showPassword ?  <VisibilityOffIcon /> :  <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
                  </Grid>
                </Grid>
              </Box>

              <Box p={3} mb={5}>
                <Button
                  sx={{
                    background: "#2DB55D !important",
                    color: "#fff",
                    width: '100%',
                    height:'50px',
                    fontFamily: "Plus Jakarta Sans",
                  }}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} sx={{ color: '#fff', }} /> : "Login"}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default Login

