import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const MultiSelectDropdown = ({ options, selectedOptions, onChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="multi-select-dropdown-label">Select Options</InputLabel>
      <Select
        labelId="multi-select-dropdown-label"
        id="multi-select-dropdown"
        className='inputFeild'
        multiple
        value={selectedOptions}
        onChange={onChange}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <span key={value}>{options.find(e => e.key === value)?.value} && </span>
            ))}
          </div>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
