import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: '#2E3033',
      light:'#FFFFFF',
      dark:'#1A1B1E'
    },
    secondary: {
      main: '#2DB55D',
      light:'#FB662A'
    },
  },
  //Customize Typography
  MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
  typography: {
    fontFamily: [
        'Signika',
        'PlusJakartaSans',
        'Inter',
        'Roboto',
    ],
    button:{
        fontStyle:'Inter',
        background:'#FB662A'
    }
  },
  shape:{
    borderRadius:10.6
  }
});

export default theme;
