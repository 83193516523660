import { createStyles, makeStyles, useTheme } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import LogoCode from "../logo.svg";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { InputBase, AppBar, Toolbar, Menu, MenuItem } from "@mui/material";
const useStyles = makeStyles((theme) =>
  createStyles({
    navbar: {
      flex: 1,
      backgroundColor: theme?.palette?.primary?.main,
      justifyContent: "space-between",
      display: "flex",
    },
    logo: {
      alignSelf: "center",
      justifySelf: "flex-start",
    },
    search: {
      display: "flex", // Added to make the search section a flex container
      alignItems: "center",
      justifySelf: "center",
      borderStyle: "solid",
      borderRadius: theme?.shape?.borderRadius,
      borderWidth: "1px",
      borderColor: theme?.palette?.primary?.dark,
      backgroundColor: theme?.palette?.primary?.main,
      maxWidth: "30%",
      height: "45px",
      color: "white",
      flex: 1, // Added to make the search section take remaining space
    },
    searchIcon: {
      marginLeft: theme?.spacing(1),
      pointerEvents: "none",
      display: "flex", // Added to center the search icon
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      width: "100%",
    },
    inputInput: {
      padding: theme?.spacing(1, 1, 1, 0),
      paddingLeft: theme?.spacing(2),
      transition: theme?.transitions.create("width"),
      width: "100%",
    },
    avatar: {
      marginLeft: theme?.spacing(2),
    },
  })
);
function Navbar({ searchQuery, setSearchQuery }) {
  const theme = useTheme(); // Access the theme object
  const location = useLocation(); // Get the current location
  const classes = useStyles(theme); // Pass the theme to useStyles
  const path = location.pathname;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    handleClose();
    navigate("/login");
    enqueueSnackbar("Logged out successfully", { variant: "success" });
  };

  return (
    <AppBar position="static">
      <Toolbar className={classes.navbar}>
        <Link className="textColor1 text3 font-jakarta" to="/">
          <img src={LogoCode} alt="logo" height="30px" />
        </Link>
        {path === "/unpublished-screening" || path === "/" ? (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{ color: "white" }}
            />
          </div>
        ) : (
          ""
        )}
        {path === "/login" ? (
          ""
        ) : (
          <>
            <AccountCircleIcon
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                opacity: "0.5",
                fontSize: "40px",
                cursor: "pointer",
              }}
            />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
