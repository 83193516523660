import { combineReducers } from 'redux';
// import { authReducer } from './authReducer';
import { userReducer } from './userReducer';


const rootReducer = combineReducers({
//   slice1: authReducer,
  user: userReducer,
});

export default rootReducer;